export default [
  {
      path: '/area-clienti/dashboard',
      name: 'area-clienti-dashboard',
      component: () => import('@/views/area-clienti/dashboard.vue'),
      meta: {
          pageTitle: 'Area Cliente',
          action: 'customer',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Area Clienti',
            },
            {
              text: 'Dashboard',
              active: true,
            },
          ],
          breadcrumb_btn_viewed: '1',
          breadcrumb_btn_variant: 'primary',
          breadcrumb_btn_icon: 'PlusIcon',
          breadcrumb_btn_to: {name: 'crm-utenti-new' },
      },
  },
  {
      path: '/area-clienti/profilo',
      name: 'area-clienti-profilo',
      component: () => import('@/views/area-clienti/profilo/index.vue'),
      meta: {
          pageTitle: 'Area Cliente',
          action: 'customer',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Area Clienti',
            },
            {
              text: 'Profilo',
              active: true,
            },
          ],
      },
  },
  {
      path: '/area-clienti/profilo/cambio-password',
      name: 'area-clienti-profilo-cambio-password',
      component: () => import('@/views/area-clienti/profilo/change-password.vue'),
      meta: {
          pageTitle: 'Area Cliente',
          action: 'customer',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Area Clienti',
            },
            {
              text: 'Profilo',
              to: {name: 'area-clienti-profilo' },
            },
            {
              text: 'Cambio Password',
              active: true,
            },
          ],
      },
  },
  {
      path: '/area-clienti/licenze',
      name: 'area-clienti-licenze',
      component: () => import('@/views/area-clienti/licenze/index.vue'),
      meta: {
          pageTitle: 'Licenze',
          action: 'customer',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Area Clienti',
            },
            {
              text: 'Licenze',
              active: true,
            },
          ],
      },
  },
  {
      path: '/area-clienti/licenze/show/:id_riga',
      name: 'area-clienti-licenze-show',
      component: () => import('@/views/area-clienti/licenze/show.vue'),
      meta: {
          pageTitle: 'Scheda Licenza',
          action: 'customer',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Area Clienti',
            },
            {
              text: 'Licenze',
              to: {name: 'area-clienti-licenze' },
            },
            {
              text: 'Scheda Licenza',
              active: true,
            },
          ],
      },
  },
  {
      path: '/area-clienti/licenze/pay/:id_riga',
      name: 'area-clienti-licenze-pay',
      component: () => import('@/views/area-clienti/licenze/pay.vue'),
      meta: {
          pageTitle: 'Scheda Licenza',
          action: 'customer',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Area Clienti',
            },
            {
              text: 'Licenze',
              to: {name: 'area-clienti-licenze' },
            },
            {
              text: 'Scheda Licenza',
              active: true,
            },
          ],
      },
  },
  {
      path: '/area-clienti/licenze/downplico/:id_riga',
      name: 'area-clienti-licenze-downplico',
      component: () => import('@/views/area-clienti/licenze/downplico.vue'),
      meta: {
          pageTitle: 'Scheda Licenza',
          action: 'customer',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Area Clienti',
            },
            {
              text: 'Licenze',
              to: {name: 'area-clienti-licenze' },
            },
            {
              text: 'Scheda Licenza',
              active: true,
            },
          ],
      },
  },
  {
      path: '/area-clienti/payments',
      name: 'area-clienti-pagamenti',
      component: () => import('@/views/area-clienti/pagamenti/index.vue'),
      meta: {
          pageTitle: 'Pagamenti',
          action: 'customer',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Area Clienti',
            },
            {
              text: 'Pagamenti',
              active: true,
            },
          ],
      },
  },
  {
      path: '/area-clienti/payments/show/:id_riga',
      name: 'area-clienti-pagamenti-show',
      component: () => import('@/views/area-clienti/pagamenti/show.vue'),
      meta: {
          pageTitle: 'Scheda Pagamento',
          action: 'customer',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Area Clienti',
            },
            {
              text: 'Pagamenti',
              to: {name: 'area-clienti-pagamenti' },
            },
            {
              text: 'Scheda Pagamento',
              active: true,
            },
          ],
      },
  },
]
